.footer {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    justify-items: center;
    align-items: center;
}

.separator {
    border-right: 1px solid #f3dc8d;
    height: 25px;
}
