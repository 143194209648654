.villagerCounter {
    padding: 7px;
    width: 75px;
    display: flex;
    gap: 5px;
}

.villagerCounterBox {
    background-color: #282e3c;
    box-shadow: 3px 3px 2px 1px black;
}

.villagerCounterZeroBox {
    color: #51545d;
}

.villagerIcon {
    font-size: 20px;
}

.villagerCounterBox .villagerIcon {
    color: #d9cba1;
}

.villagerCounterBox .villagersCount {
    color: white;
}


