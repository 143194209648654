.villagersByResourcesBlock {
    border: 2px solid #393e49;
    background-color: #161a23;
    width: 150px;
    height: 165px;
}

.resourceVillagerBlock {
    display: flex;
    align-items: center;
    gap: 10px;
}

.resourceIcon {
    height: 40px;
}

.seePreciseNumbersInput {
    padding-top: 10px;
    color: #9daab2;
    font-size: 15px;
}
