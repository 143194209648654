.upgradesBlock {
    padding-top: 5px;
}

.singleUpgrade {
    padding: 1px;
}

.upgradesRow {
    display: flex;
    gap: 10px;
}

.upgradesColumn {
    display: flex;
    flex-flow: column;
    gap: 10px;
}
