.item {
    padding: 2px;
    width: 70px;
    height: 107px;
    box-sizing: border-box;
    background-color: #754e2f;
    text-align: center;
}

.item:hover {
    border: 2px solid white;
}

.selectedItem {
    border: 1px solid white;
}

.itemIconBox {
    width: 65px;
    height: 65px;
}

.itemNameBlock {
    color: white;
    font-size: 12px;
    overflow: hidden;
    line-height: 15px;
    text-overflow: ellipsis;
}

