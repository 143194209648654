.cattleRanch {
    display: flex;
    gap: 5px;
    color: #f3dc8d;
    font-size: 30px;
    flex-wrap: wrap;
    align-items: center;
}

.cattleRanchIcon {
    height: 60px;
}

.copyButton {
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
}
