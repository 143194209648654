.sultanhaniTradeNetwork {
    display: flex;
    gap: 5px;
    color: #f3dc8d;
    font-size: 30px;
    flex-wrap: wrap;
    align-items: center;
}

.landmarkIcon {
    height: 60px;
}
