.civIcon {
    width: 100px;
    height: 56px;
}

.dropdown {
    padding: 6px;
}

.toggle, .toggle:hover, .toggle:focus, .toggle:active {
    border: none;
    box-shadow: none;
}

.itemIcon {
    width: 25px;
    height: 14px;
}

span {
    color: #c4ccd0;
}
