.hidden {
    display: none;
}

.civilizationSpecificFeature {
    display: flex;
    gap: 5px;
    color: #f3dc8d;
    font-size: 30px;
    flex-wrap: wrap;
    align-items: center;
}

.civilizationSpecificFeatureIcon {
    height: 60px;
}

.civilizationSpecificFeature .items {
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upgradesRow {
    display: flex;
    gap: 10px;
}

.civilizationSpecificFeatures {
    display: flex;
    gap: 10px;
    flex-direction: column;
    color: white;
}

.upgradeGroup {
    padding: 1px;
    display: flex;
    column-gap: 2px;
}
