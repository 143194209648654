[type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

[type=radio] + img {
    cursor: pointer;
}

[type=radio]:checked + img {
    outline: 2px solid white;
}

.foodSourceTitle {
    padding-bottom: 3px;
}

.foodSourceRadioIcon {
    width: 48px;
    height: 48px;
}
